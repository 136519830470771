import React, { useContext } from "react";
import { FaCaretRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import { ButtonContent } from "../global/boton/ButtonContent";
import { BiPhoneCall, BiCalendar } from "react-icons/bi";
import { MdOutlineEmail } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import CounterUp from "../global/CounterUp";
import Values from "../About/Values";
import Map from "../Contact/MapContent";
import { GiForkKnifeSpoon } from "react-icons/gi";

// Arrglo de precio de platos por categoria

function ServicesDetail2() {
  const { rpdata } = useContext(GlobalDataContext);
  const { id } = useParams();
  // console.log(id);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <BaseLayout PageName={`${rpdata?.dbPrincipal?.name}`}>
      {rpdata?.dbServices?.map((item, index) => {
        if (
          item.name
            .replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-")
            .toLowerCase() === id
        ) {
          return (
            <div key={index}>
              <TransparentHeader
                bgimg={item.description[0].img}
                headertitle={item.name}
                Subheader={"Our Menu"}
              />
              <div className="mainMenu">
                <div className="w-[95%] md:w-[90%] mx-auto my-10 block md:flex md:gap-5 z-10 relative">
                  <div className="w-full md:w-9/12 shadow-lg shadow-neutral-800/30 p-5 md:p-10 mb-5 rounded-tl-[40px] rounded-br-[40px] bg-white">
                  {/*   <img
                      src={item.description[0].img}
                      alt="no found"
                      className="w-full object-cover h-auto md:h-[500px] rounded-tl-3xl rounded-br-3xl"
                    /> */}
                    <div className="w-full h-auto flex justify-center text-center">
                      <h3 className="w-auto h-auto py-3 px-8 bg-2 rounded-tl-3xl rounded-br-3xl -mt-12 text-white">
                        {item.name}
                      </h3>
                    </div>
                    <div className="pt-10">
                      {rpdata?.works?.map((items, index) => {
                        if (items.service === item.name) {
                          return (
                            <div
                              key={index}
                              className="w-[90%] mx-auto flex items-center mb-7"
                            >
                              <div className="bg-[#FF7400] p-10 text-white text-[30px] rounded-full">
                              <GiForkKnifeSpoon />

                                {/* <img
                                  src={items.gallery}
                                  alt="images menus"
                                  className="w-[130px] h-[90px] md:w-[120px] md:h-[100px] rounded-full object-cover shadow-[#9c9c9c] shadow-lg"
                                /> */}
                               
                              </div>
                              <div className="w-full flex">
                                <div className="w-full md:w-[80%] border-none md:border-dotted md:border-b-2 ">
                                  <div className="w-auto ml-3 md:ml-5 flex flex-col justify-center">
                                    <p className="capitalize font-semibold text-[20px] ">
                                      {items.name}
                                    </p>
                                    <p className=" text-[#404040] font-mono pr-0 md:pr-3">
                                      {items.description}
                                    </p>
                                    <p className=" text-[#000] font-bold text-[20px] pr-0 md:pr-3">
                                      ${items.subdescription}
                                    </p>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>

                    <div className="flex justify-center items-center w-full h-auto pt-10">
                      <ButtonContent
                        btnStyle={"one"}
                        btnUrl={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                        btnName={"Order Now"}
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/4 shadow-lg bgBloqueServicesDetail p-5 md:p-5 mb-5 text-white rounded-tl-3xl rounded-br-3xl ">
                    <div className="pb-5">
                      <h4 className="text-center md:text-start font-bold py-5">
                        {" "}
                        Our Menu
                      </h4>
                      <ul className="px-1 md:px-4">
                        {rpdata?.dbServices?.map((nam, index) => {
                          return (
                            <li key={index}>
                              <Link
                                to={`/${nam.name
                                  .replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-")
                                  .toLowerCase()}`}
                                onClick={goToTop}
                                className="flex items-center text-white pb-3"
                              >
                                <FaCaretRight />
                                <span className="pl-2">{nam.name}</span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="pb-5">
                      <h4 className="text-center md:text-start font-bold pb-5">
                        Contact
                      </h4>
                      <ul className="px-1 md:px-4">
                        {rpdata?.dbPrincipal?.phones.map((phone, index) => {
                          return (
                            <li
                              key={index}
                              className="flex items-center text-white pb-3"
                            >
                              <a
                                href={`tel:+1${phone.phone}`}
                                className="flex items-center"
                              >
                                <BiPhoneCall />
                                <span className="pl-2">{phone.phone}</span>
                              </a>
                            </li>
                          );
                        })}
                        {rpdata?.dbPrincipal?.emails.map((email, index) => {
                          return (
                            <li
                              key={index}
                              className="flex items-center text-white pb-3"
                            >
                              <a
                                href={`mailto:${email.email}`}
                                className="flex items-center"
                              >
                                <MdOutlineEmail />
                                <span className="pl-2">{email.email}</span>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="pb-5">
                      <h4 className="text-center md:text-start font-bold pb-5">
                        Opening Hours
                      </h4>
                      <ul className="px-1 md:px-4">
                        <li
                          key={index}
                          className="flex items-center text-white pb-3"
                        >
                          <BiCalendar />
                          <span className="pl-2">
                            {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                          </span>
                        </li>
                        <li
                          key={index}
                          className="flex items-center text-white pb-3"
                        >
                          <FaRegClock />
                          <span className="pl-2">
                            {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                          </span>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else return console.log("Servicio no encontrado");
      })}

      <CounterUp image={rpdata?.gallery?.[6]} />

      <div className="mt-[-110px]">
        <Values />
      </div>
      <Map />
    </BaseLayout>
  );
}

export default ServicesDetail2;
