import React from "react";


const Form = (props) => {

  return (
    <div className="py-10 px-2">
      <div class="elfsight-app-c1bb9e7a-51f9-40a3-9762-705487f01ed1" data-elfsight-app-lazy></div>
    </div >
  );
};

export default Form;


// variable del formulario:
// tname=""
// tlname=""
// temail=""
// tphone=""
// taddress=""
// ttypeservice=""
// tbestway=""
// tcheckphone=""
// tcheckcorreo=""
// tmessage=""
// tbutton=""
// isenglish="true"
