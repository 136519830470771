import React from "react";

const TransparentHeader = ({ headertitle, bgimg, Subheader }) => {
  return (
    <div
      className="breadcrumb-area"
      style={{ backgroundImage: `url("${bgimg ? bgimg : 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/Restaurante%2Fbg-banner-inner-1024x304.png?alt=media&token=44b6b2b7-9e83-483f-b770-ad58500cb002'}")` }}
    >
       <img
          src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Restaurante%2FSlice%20Top.png?alt=media&token=2de29ecf-9425-4778-9606-0a59172af59d"}
          alt='no found'
          loading='lazy'
          className='absolute -top-3 left-0 w-[100%] md:h-[6%] h-[10%] z-[4]'
        />
        <img
          src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Restaurante%2FSlice%20Buttom_1.png?alt=media&token=1c5e029f-a661-48e2-90b2-62485f08a20b"}
          alt='no found'
          loading='lazy'
          className='absolute -bottom-1 left-0 w-[100%] h-[9%] md:h-[120px] z-[4]'
        />
      <div className="text-center relative">
        <h1 className="text-white px-5 md:px-[20%] pb-5">{headertitle}</h1>
        {
          headertitle === Subheader ?
            <h6 className="text-white">Home - {Subheader}</h6>
            :
            <h6 className="text-white">{Subheader}</h6>
        }
      </div>
    </div>
  );
};

export default TransparentHeader;
